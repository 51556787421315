@import url(https://fonts.googleapis.com/css?family=Mukta+Vaani:200,400,700&display=swap);
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

h3.patrocinado {
  font-size: 4rem;
  font-weight: bold;
  color: white;
  display: inline-block;
  margin: 0 15px;
}

a,
.btnTypeAnchor {
  color: #00b4e6;
}

a.btnTypeAnchor:link,
a.btnTypeAnchor:visited,
a.btnTypeAnchor:active,
button.btnTypeAnchor:hover {
  color: #00b4e6;
  text-decoration: none;
}

.numSeries {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  padding-bottom: 1rem;
  padding-left: 1rem;
  color: white;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group select {
  height: auto;
  border-radius: 2rem;
}
.form-label-group select {
  text-align-last: center;
}

.form-label-group > input,
.form-label-group > select,
.form-label-group > label {
  padding: 0.75rem 1.5rem;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group > label input:focus {
  color: #777;
  font: 6px;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.btn-primary {
  background-color: #00b4e6;
  border-color: #00b4e6;
}

.btn-google {
  color: white;
  background-color: #ea4335;
}

.btn-facebook {
  color: white;
  background-color: #3b5998;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

/* Estilos de formularios */
#formDoctor {
  transition: opacity 0.3s ease-in-out;
}

#formCliente {
  transition: opacity 0.3s ease-in-out;
  display: none;
  opacity: 0;
}

/* Toggle classes botón fix bug visual */
btn-outline-primary:hover {
  color: #007bff;
  background-color: transparent;
}

@media (max-width: 1399.98px) {
  .numSeries {
    position: relative;
    padding: 2rem 0;
    color: white;
    text-align: center;
  }
}
/* body {
  font-family: "Mukta Vaani", sans-serif;
  background-image: none;
  background-color: rgba(201, 215, 222, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
} */
.ColSpan2 {
  padding: 0 .75rem !important;
}
@charset "UTF-8";
/* CSS Document */

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

html {
  font-size: 14px;
  height: 100%;
}

body {
  font-family: "Mukta Vaani", sans-serif;
  /* background-color: #00b4e6; */
  background-color: rgba(201, 215, 222, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.bodyLogin {
  background-image: url(/static/media/imgProgramBayer.ed59eb61.svg);
  -webkit-backdrop-filter: brightness(0.75);
          backdrop-filter: brightness(0.75);
  background-size: conver;
  background-position: top center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: auto !important;
}

#logoBayLeft {
  max-height: 25%;
}

#CardLogin a {
  color: rgba(54, 153, 196, 1);
}

header {
  border-bottom: solid 3px rgba(54, 153, 196, 1);
}

.centerFormInput {
  text-align: center !important;
}

.form-control {
  height: auto;
}

.form-check-label {
  padding-left: 0.5rem;
}

p {
  margin-bottom: 0;
}
.LogoBayerProgramas {
  max-width: 70px;
}
.LogoFindtrackProgramas {
  max-width: 200px;
}
/*.container-fluid.container-rest {*/
.tarjetas {
  /*background-color: white;
	 background-image: url("images/Main-background.jpg");
	background-repeat: no-repeat;
	background-position: bottom;
	overflow: hidden;*/
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
  /*height: 100%;*/
}
.card {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card .card-body {
  padding: 2rem;
}
.card-header h4 {
  color: rgba(46, 55, 66, 1);
}
.card-body h5,
.anuncio,
.modal-title,
.card-h5 {
  color: rgba(46, 55, 66, 1);
}
.card-body h6 {
  color: rgba(46, 55, 66, 1);
}
.anuncioPositivo {
  color: #006480;
}
.anuncioNegativo {
  color: red !important;
}
.card .cardBHeader {
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
}
.card-header.card-promos-header {
  background-color: rgba(0, 0, 0, 0.09);
}
/* .card-header.card-promos-header h4 {
  color: white;
} */
/* #navegacion{
	-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
	background-image: url("images/Navbar.png");
	background-position: left top;
	background-repeat: no-repeat;
	background-size: contain;
} */
.nav {
  font-size: smaller !important;
}
.bg-nav {
  /*background-color: #DCDCDC;*/
  background-color: white;
}
.navbar-brand {
  color: #006480 !important;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  /*color: #0099C2;*/
  color: #00c8ff;
}
.navbar-light .navbar-nav .nav-link:hover {
  /*color: #0099C2;*/
  color: #00c8ff;
}
.navbar-light .navbar-nav .nav-link {
  /*color: #003240;*/
  color: #ffffff;
}
#NavsPagos .nav-link.active {
  background-color: rgba(54, 153, 196, 1) !important;
  color: white !important;
}
h1 {
  font-size: 2rem;
}
h1,
h2,
h3,
h4,
h5 {
  color: rgba(46, 55, 66, 1);
  margin: 0px;
}
h5.programas {
  color: rgba(54, 153, 196, 1);
}
h6 {
  color: rgba(46, 55, 66, 1);
}
h6 a {
  color: rgba(46, 55, 66, 1) !important;
}
.h6-especial {
  font-size: 1.15rem;
}
.btnPreferencias {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}
.headPrint {
  background-color: rgba(46, 55, 66, 1);
}
.btnTypeAnchor,
.btnTypeLink {
  vertical-align: baseline;
  padding: 0;
}
a,
.btnTypeLink {
  color: rgba(54, 153, 196, 1);
}
a,
.btnTypeAnchor {
  color: rgb(145, 208, 235);
}

a:link,
a:visited,
a:active {
  color: rgba(54, 153, 196, 1);
  text-decoration: none;
}
tbody a {
  color: rgba(54, 153, 196, 1) !important;
}
.tableWarp {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 1px solid black;
}
.table td {
  white-space: nowrap;
}
.position-sticky a {
  color: white;
}
.page-link,
.nav-link {
  color: rgba(46, 55, 66, 1) !important;
}
.botonMenu {
  color: #ffffff !important;
}
.btn-primary {
  background-color: rgba(54, 153, 196, 1);
  border-color: rgba(54, 153, 196, 1);
}
.btn-primary:hover {
  background-color: rgba(46, 55, 66, 1);
  border-color: rgba(46, 55, 66, 1);
}
.btn-primary:active {
  background-color: #2e3742 !important;
  border-color: #2e3742 !important;
}
.btn-primary {
  color: #ffffff !important;
}
.btn-avance-retroceso {
  min-width: 100px;
}
.btn-APCi {
  background-color: #7952b3 !important;
  border-color: #7952b3 !important;
  color: white;
}
.btn-APCi:hover {
  color: white;
}
.division {
  border-bottom: 2px rgba(54, 153, 196, 1) solid;
}
.division2 {
  border-bottom: 2px #f7ec66 solid;
  margin-bottom: 1rem;
}
.division3 {
  border-bottom: 1px #dcdcdc solid;
  margin-bottom: 1rem;
  height: 1px;
  width: 100%;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #00c8ff;
  border-color: #0099c2;
}
.placeholder {
  font-style: italic !important;
}
.tachado {
  text-decoration: line-through;
  color: red;
}
.mejorPrecio {
  color: darkgreen;
}
.imgProgram {
  background-image: url(/static/media/imgProgram.ec8a0c21.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}
.imgProgramBayer {
  background-image: url(/static/media/imgProgramBayer.ed59eb61.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}
.position-sticky {
  top: 0;
}
.menu-lateral-a-azul {
  color: rgba(54, 153, 196, 1) !important;
}
.txtBtnMenu {
  text-align: left;
}
footer {
  position: relative !important;
}
footer a {
  color: rgba(54, 153, 196, 1) !important;
}

/*/////////////////////
//////Transiciones/////
/////////////////////*/
#formDoctor {
  transition: opacity 0.3s ease-in-out;
}

#formCliente {
  transition: opacity 0.3s ease-in-out;
}

/*/////////////////////
//////Formularios//////
/////////////////////*/

.onClickDivs {
  cursor: pointer;
}

#solicitudResumen {
  display: none;
}

.form-signin {
  width: 100%;
}

.form-signin .btn, .btnModalLogin {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: 0.75rem 1.5rem;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group > label input:focus {
  color: #777;
  font: 6px;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/*/////////////////////
//////Animaciones//////
/////////////////////*/

.cajaCerrada {
  display: none;
}

.cajaAbierta {
  display: contents;
}

@keyframes fadeDiv {
  from {
    height: 0;
  }

  to {
    height: auto;
  }
}

.oculto {
  width: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.fadeIn {
  width: 100%;
  display: flex;
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.fadeOut {
  width: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

/*/////////////////////
//////Breadcrumbs//////
/////////////////////*/
.btn-crumb {
  width: 100%;
}
.breadcrumb-item1 {
  background-color: rgba(0, 200, 250, 0.1);
  border-radius: 5px;
  padding: 10px 0;
}

.breadcrumb-item1 p {
  color: white;
}

.breadcrumb-item2 {
  background-color: #00c8ff;
  border-radius: 5px;
  padding: 10px 0;
}

.breadcrumb-item2 p {
  color: white;
}

/*///////////////////////////////////////////////////////////////
///////////////////////// Canvas /////////////////////////
///////////////////////////////////////////////////////////////*/

.canvas {
  position: relative;
  margin: 1px;
  margin-left: 0px;
  border: 0.3rem solid #3a87ad;
  /* height: 200px;
  width: 300px; */
}

/*///////////////////////////////////////////////////////////////
///////////////////////// Legales /////////////////////////
///////////////////////////////////////////////////////////////*/
.legales {
  font-size: 1rem;
  color: slategray;
}

.legales span a {
  color: slategray;
}

.legales span a:hover {
  color: #00b4e6;
}

/*///////////////////////////////////////////////////////////////
///////////////////////// Estatus estyles /////////////////////////
///////////////////////////////////////////////////////////////*/

.EstatusTrabajando,
.EstatusCompleto,
.EstatusEspera,
.EstatusIncidencia {
  background-color: rgba(18, 179, 0, 0.8);
  color: white;
}
.EstatusTrabajando {
  background-color: rgba(18, 179, 0, 0.8);
}

.EstatusTrabajando {
  background-color: rgba(233, 141, 26, 0.8);
}

.EstatusEspera {
  background-color: rgba(0, 113, 179, 0.8);
}
.EstatusIncidencia {
  background-color: rgba(173, 20, 20, 0.8);
}

/*/////////////////////
//////Animaciones//////
/////////////////////*/

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon {
  animation: 3s rotate linear infinite;
  transform-origin: 50% 50%;
}

/*
//////////////////////////////////////////////////
////////////////// Animaciones //////////////////
//////////////////////////////////////////////////
*/
.apartadoSolcitud {
  display: none;
}
#buscarMedico {
  display: block;
}

/*/////////////////////
/////Media Querys//////
/////////////////////*/

@media (min-width: 992px) {
  .navbar-expand-lg {
    justify-content: flex-end !important;
  }
}

@media (max-width: 420px) {
  html {
    font-size: 0.7rem;
  }
  .botonMenu {
    font-size: 1.5rem;
  }
}

/* @media screen {
  #printSection {
      display: none;
  }
} */

@media print {
  @page {
    size: A4; /* auto is the initial value */

    /* this affects the margin in the printer settings */
    margin: 0mm 5mm 5mm 5mm;
    max-width: 100%;
  }
  /* body * {
    margin: 0;
    visibility:hidden;
  }
  #printSection, #printSection * {
    visibility:visible;
	 -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  #printSection.printApCI {
    margin: 0;
    padding: 0;
  } */
}

.FSalida {
  background-image: url(/static/media/Formato-Solicitud-DIGITAL.9a8d3606.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1824px;
  max-width: 816px;
  width: 100%;
  margin: 0 auto;
}

.FSalida .datas {
  position: absolute;
  background-color: red;
  height: 25px;
  width: 25px;
}

.Dfolio {
  width: 228px !important;
  top: 10.5vh;
  right: 11vw;
}

.Dnombre {
  top: 26.5vh;
  left: 11.5vw;
  width: 36vw !important;
}

.DnacDia {
  top: 40.5vh;
  left: 13vw;
  width: 3.4vw !important;
}
.DnacMes {
  top: 40.5vh;
  left: 17.5vw;
  width: 3.4vw !important;
}
.DnacYear {
  top: 40.5vh;
  left: 22vw;
  width: 3.4vw !important;
}
.DgenMasc {
  top: 35.9vh;
  right: 21.3vw;
  width: 1.4vw !important;
}
.DgenFem {
  top: 39.9vh;
  right: 21.3vw;
  width: 1.4vw !important;
}
.DgenUndef {
  top: 43.8vh;
  right: 21.3vw;
  width: 1.4vw !important;
}
.DnomMed {
  top: 58vh;
  left: 17vw;
  width: 31vw !important;
}
.DmailMed {
  top: 62.5vh;
  left: 17vw;
  width: 31vw !important;
}
.DtelMed {
  top: 67.4vh;
  left: 17vw;
  width: 31vw !important;
}
.DinfMues1 {
  top: 83.4vh;
  left: 10.8vw;
  width: 1.5vw !important;
}
.DinfMues2 {
  top: 83.4vh;
  left: 23.3vw;
  width: 1.5vw !important;
}
.DinfMues3 {
  top: 89.6vh;
  left: 10.8vw;
  width: 1.5vw !important;
}
.DinfMues4 {
  top: 89.6vh;
  left: 23.3vw;
  width: 1.5vw !important;
}
.DinfMes {
  top: 86.2vh;
  right: 19.3vw;
  width: 3.5vw !important;
}
.DinfYear {
  top: 86.2vh;
  right: 13.9vw;
  width: 3.5vw !important;
}
.DinfTratPrev1 {
  top: 110vh;
  left: 22vw;
  width: 1.5vw !important;
}
.DinfTratPrev2 {
  top: 110vh;
  left: 33.5vw;
  width: 1.5vw !important;
}
.DinfTratPrev3 {
  top: 110vh;
  left: 37.5vw;
  width: 1.5vw !important;
}
.DinfColHosp {
  top: 125vh;
  left: 15.5vw;
  width: 18.5vw !important;
}
.DinfColHosp2 {
  top: 129vh;
  left: 11.5vw;
  width: 22.5vw !important;
}
.DinfColDir {
  top: 134vh;
  left: 15.5vw;
  width: 18.5vw !important;
}
.DinfColDir2 {
  top: 138vh;
  left: 11.5vw;
  width: 22.5vw !important;
}
.DinfColRef {
  top: 146.7vh;
  left: 11.5vw;
  width: 22.5vw !important;
}
.DinfColRef2 {
  top: 150.7vh;
  left: 11.5vw;
  width: 22.5vw !important;
}
.DlabEnvMuestra {
  top: 155.7vh;
  left: 29.5vw;
  width: 18.5vw !important;
}
.DlabEnvMuestra2 {
  top: 159.7vh;
  left: 11.5vw;
  width: 36.5vw !important;
}
.DhoraEntrega1 {
  top: 131.5vh;
  left: 36.5vw;
  width: 1.5vw !important;
}
.DhoraEntrega2 {
  top: 137.2vh;
  left: 36.5vw;
  width: 1.5vw !important;
}
.DhoraEntrega3 {
  top: 143vh;
  left: 36.5vw;
  width: 1.5vw !important;
}
.DhoraEntrega4 {
  top: 148.8vh;
  left: 36.5vw;
  width: 1.5vw !important;
}
.DFirma {
  left: 16.5vw;
  bottom: 24vh;
  width: 10.5vw !important;
  height: 8vh;
}

/* .Fsalida{
   width: 100%;
	background-color: white;
}

.Fsalida {
   border-collapse:collapse;
   border-spacing:0;
}
.Fsalida  td{
   border-color:none;
   border-style:solid;
   border-width:1px;
   overflow:hidden;
   padding:10px 5px;
   word-break:normal;
}
.Fsalida  th{
   border-color:none;
   border-style:solid;
   border-width:1px;
   font-weight:normal;
   overflow:hidden;
   padding:10px 5px;
   word-break:normal;
}

.Fsalida  .datos{
   background-color:#c9d7de;
   border-color:#c9d7de;
   color:#2e3742;
   text-align:left;
   vertical-align:top;
   font-weight: bold;
   font-style:italic;
}

.Fsalida  .textos-justificados{
   background-color:#ffffff;
   border-color:#ffffff;
   text-align:justify;
   vertical-align:top;
}

.Fsalida  .textos-centrados{
   background-color:#ffffff;
   border-color:#ffffff;
   text-align:center;
   vertical-align:top;
}

.Fsalida  .textos-middle{
   background-color:#ffffff;
   border-color:#ffffff;
   color:#2e3742;
   font-weight:bold;
   text-align:center;
   vertical-align:middle;
}

.Fsalida  .titulos-blancos{
   background-color:#3699c4;
   border-color:#3699c4;
   color:#ffffff;
   font-weight:bold;
   text-align:left;
   vertical-align:top;
	font-size: 1.4rem;
}

.Fsalida  .textos-bottom{
   background-color:#ffffff;
   border-color:#ffffff;
   color:#2e3742;
   text-align:center;
   vertical-align:bottom;
   height: 150px;
}

.Fsalida .spacer {
    padding: 0px;
	 height:20px;
	 border-color: white;
} */

.percent-10 {
  width: 10%;
}

.percent-20 {
  width: 20%;
}

.percent-30 {
  width: 30%;
}

.percent-40 {
  width: 40%;
}

.percent-50 {
  width: 50%;
}

.percent-60 {
  width: 60%;
}

.percent-70 {
  width: 70%;
}

.percent-80 {
  width: 80%;
}

.percent-90 {
  width: 90%;
}

.percent-100 {
  width: 100%;
}

.custom-file-label {
  right: 15px;
  left: 15px;
}

/*
//////////////////////////////////////////////////
////////////////// Animaciones //////////////////
//////////////////////////////////////////////////
*/

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
.scale-up-center {
  animation: scale-up-center 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

/*
//////////////////////////////////////////////////
//////////////// Aviso privacidad ////////////////
//////////////////////////////////////////////////
*/
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}

/*
//////////////////////////////////////////////////
///////////////// Checkbox slides ////////////////
//////////////////////////////////////////////////
*/

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
  margin-bottom: 0;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*
//////////////////////////////////////////////////
/////////////// Estatus de lectura ///////////////
//////////////////////////////////////////////////
*/

.messageColor {
  color: grey;
}

.messageNoRead {
  position: relative;
}

.messageNoRead::after {
  background-color: red;
  border-radius: 4px;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: -3px;
  top: -3px;
  width: 8px;
  z-index: 10;
}

.messageRead {
  position: relative;
}

.messageRead::after {
  align-items: center;
  background-color: green;
  border-radius: 4px;
  color: white;
  content: "✓";
  display: flex;
  font-size: 6px;
  height: 8px;
  justify-content: center;
  position: absolute;
  right: -3px;
  top: -3px;
  width: 8px;
  z-index: 10;
}

